<template>
  <div
      v-if="selectedNewsItem"
      class="object-full"
  >
    <swiper
        class="photos"
        :options="{
        slidesPerView: 'auto',
        touchRatio: 2
      }">
      <swiper-slide
          v-for="(img, index) in selectedNewsItem.images"
          :key="index">
        <img :src="img" />
      </swiper-slide>
    </swiper>

    <div class="content">
      <div class="left-side">
        <div class="title">
          {{ selectedNewsItem.title }}
        </div>
      </div>

      <scrolled-content class="right-side">
        <tag
            v-if="selectedNewsItem.type.name"
            :title="selectedNewsItem.type.name"
        />
        <div
            v-html="selectedNewsItem.description"
            class="text">
        </div>
      </scrolled-content>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";

export default {
  components: {
    ScrolledContent,
    Tag,
  },

  computed: {
    ...mapGetters(["selectedNewsItem"]),
  },
  watch: {
    $route: "fetchData"
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.selectedNewsItem || this.selectedNewsItem.id != this.$route.params.id)
        api.getNewsItem(this.$route.params.id).then(res => {
          this.$store.dispatch("setSelectedNewsItem", res.data);
          console.log(res.data);
        });
      if (!this.events) {
        this.$store.dispatch("fetchNews");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";


.object-full {
  padding-bottom: 100px;
  margin: 0 32px;
  border-bottom: 4px solid rgba(61, 61, 60, .6);

  .photos {
    margin-top: 56px;

    .swiper-slide {
      width: 884px;
      height: 442px;

      margin-left: 32px;
      margin-right: 12px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }

  .content {
    margin-top: 32px;
    display: flex;
    text-align: left;

    .left-side {
      width: 488px;
      // margin-left: 32px;
      margin-right: 40px;

      .title {
        width: 488px;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: rgba(#fff, 0.97);
        padding-bottom: 40px;
        margin-bottom: 32px;
      }
    }

    .right-side {
      width: 508px;
      // width: auto;
      max-height: 646px;

      .text {
        margin-top: 32px;
        width: 488px;

        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.1px;
        color: rgba(#fff, 0.8);
        position: relative;
        padding-bottom: 32px;
      }
    }
  }

  .adds {
    padding-bottom: 10px;
    border-bottom: 4px solid #3d3d3b;
  }

  .buy-ticket {
    width: 100%;
    height: 100px;
    margin-top: 32px;
    font-size: 32px;
    font-weight: normal;
    background: linear-gradient(180deg, #62AFFF 0%, #0B83FF 105.9%);
    border-radius: 150px;
  }
}
</style>
